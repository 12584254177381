body {

    .claim-container {
        color: #ffffff;
        background-color: #5f5f5f;
        border-radius: 0.5em;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.5em;
    }

    .info-container {
        border-radius: 0.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5em;
    }

    .info-area {
        color: #000000;
        background-color: #37FD12;
        border-radius: 0.5em;
        display: flex;
        align-items: center;
    }

    .info-box {
        color: #000000;
        background-color: #37FD12;
        border-radius: 0.5em;
        padding: 1em;
        // display: flex;
        // align-items: center;
    }

    .claim-btn {
        background-color: #2e3192 !important;
    }

    .blank-area {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 110px);
    }

    .align-center {
        align-items: center;
    }

    .card {
        color: #000000;
        background-color: #ffffff;
    }

    .balance-area {
        background-color: transparent;
    }

    .btn {
        background-color: #00396D;
        border-radius: 0.2em !important;
        border: none;
        font-size: 20px;
        outline: none;
        padding: 15px 30px;

        &:active {
            border: none;
            outline: none;
        }

        &:hover {
            border: none;
            outline: none;
        }

        &:focus {
            border: none;
            outline: none;
            box-shadow: none !important;
        }
    }

    h1 {
        font-weight: 800;
        font-size: 14px;
        line-height: 2;
        text-transform: uppercase !important;
        margin: 0;
    }

    .main {
        padding: 1rem;

        .card-header {
            padding: 1rem;
            font-size: 12px;
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .modal-footer {
            padding: 1rem !important;
            border: none;

            button {
                margin: 0 !important;
                color: #f0f8ff;
                font-size: 15px;
                font-weight: 500;
            }
        }

        hr {
            opacity: 0.1;
            height: 0.5px !important;
        }
    }

    .bg-warning {
        background-color: rgb(255, 166, 0) !important;
        font-size: 20px;
        color: #f0f8ff;
        font-weight: 500;
    }

    .main-frame {
        height: calc(100vh - 77px);
        // background-image: url("./img/background.png");
        // background-size: cover;
        overflow-y: auto;
        overflow-x: hidden;

        .col {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }

    .link-area {
        background-color: #5f5f5f;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
}

::-webkit-scrollbar-track {
    box-shadow: rgb(224 244 234) 0px 0px 2px inset;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 8px;
}

::-webkit-scrollbar {
    width: 2px;
}