@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Exo 2', sans-serif;
  font-weight: 500;
}

.progress {
  background-color: transparent !important;
  border-color: #272727;
  border-width: 2px;
  border-radius: 5px !important;
}

.progress-bar {
  background-color: #272727 !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
}

.blur-bg {
  backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(60px);
}